import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";
import { useSpring, animated } from "react-spring";

import Svg from "../../Atoms/Svg";
import Link from "../../Atoms/Link";
import Header from "../../Molecules/Header";
import CategoryNavigationLinks from "../../Molecules/CategoryNavigationLinks";
import { adapter as headerAdapter } from "../../Organisms/BurgerMenu";

import {
  colors,
  scaleSpacing,
  mediaquery,
  zIndexes,
  rem,
} from "../../../../styles/theme";
import { NotoLink } from "../../Atoms/Typography";
import { button } from "../../../../styles/buttons";

import { InplayerContext } from "../../../contexts/inplayerContext";
import { UIContext } from "../../../contexts/ui";

const Wrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: ${zIndexes.header};
  pointer-events: none;
`;

const MenuOverlay = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: ${zIndexes.modal};
  pointer-events: all;

  ${mediaquery.md(css`
    min-height: calc(var(--initialvh, 1vh) * 100);
  `)};
`;

const MenuWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  display: block;
  background-color: ${({ bgcolor }) => bgcolor};
  z-index: ${zIndexes.modal};
  pointer-events: all;
  height: ${({ offset }) => (offset ? `calc(100vh - ${offset}px)` : "100vh")};
  min-height: ${({ offset }) =>
    offset ? `calc(100vh - ${offset}px)` : "100vh"};
  /* mobile viewport bug 100 vhfix on webkit */
  min-height: -webkit-fill-available;

  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  ${mediaquery.md(css`
    background-color: ${colors.darkgrey2};
    width: ${rem(408)};
  `)};
`;

const LogoWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    width: ${rem(6)};
    left: 0;
    top: 0;
    height: 100%;
    background: ${({ color }) => color};
  }

  span {
    margin-top: ${scaleSpacing(7.5)};
    margin-right: ${scaleSpacing(6)};
    margin-bottom: ${scaleSpacing(8.5)};
    margin-left: ${scaleSpacing(8)};
  }

  ${mediaquery.md(css`
    border-left: ${rem(6)} solid transparent;
    justify-content: flex-start;

    &:before {
      display: none;
    }

    span {
      margin: ${scaleSpacing(10.4)} ${scaleSpacing(10.5)};
    }
  `)};
`;

const LogoLink = styled(Link)`
  display: inline-block;
  ${mediaquery.md(css`
    margin-left: ${scaleSpacing(8)};
    display: none;
  `)};
`;

const LogoSvg = styled(Svg)`
  width: ${rem(155)};
  height: ${rem(32)};
  ${({ top }) => css`
    margin-top: -${top && `${scaleSpacing(top.sm)}`};
    ${mediaquery.md(css`
      margin-top: -${top && `${scaleSpacing(top.md)}`};
    `)};
  `};
`;
const Close = styled(Svg)`
  ${mediaquery.md(css`
    display: block;
    svg {
      fill: white;
    }
  `)};
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.darkgrey3};
  margin: 0 auto ${scaleSpacing(10)};
  display: none;

  ${mediaquery.md(css`
    display: block;
  `)};
`;

const Sections = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SectionWrapper = styled.div`
  background-color: ${colors.darkgrey2};
  transition: all ease 0.8s;
  border-left: ${({ color }) => `6px solid ${color || colors.cinema}`};
  padding-top: ${scaleSpacing(12)};
  padding-right: ${scaleSpacing(4)};
  padding-bottom: ${scaleSpacing(6)};
  padding-left: ${scaleSpacing(7)};
  box-sizing: content-box;
  display: ${({ iscurrent }) => (!iscurrent ? "block" : "none")};
  order: ${({ iscurrent }) => (iscurrent ? "-1" : "unset")};

  &:hover {
    border-left: ${({ color }) => `6px solid ${color || colors.cinema}`};
  }
  &:last-child {
    padding-bottom: ${scaleSpacing(12)};
    padding-bottom: ${scaleSpacing(12)};
  }

  ${mediaquery.md(css`
    display: block;
    border-left: 6px solid transparent;
    padding: ${scaleSpacing(6)} ${scaleSpacing(8)} ${scaleSpacing(6)}
      ${scaleSpacing(10.5)};
    &:last-child {
      padding-bottom: ${scaleSpacing(6)};
    }
  `)};
`;

const Description = styled.span`
  display: inline-block;
  margin-right: ${scaleSpacing(6)};
  ${NotoLink};
  color: ${colors.mediumgrey1};
`;

const ColLinkWrapper = styled.div`
  display: flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColLink = styled(Link)`
  flex: 1;
  display: block;
`;

const ArrowLink = styled(Link)`
  height: ${rem(40)};
  width: ${rem(32)};

  display: flex;
  align-items: center;
  justify-content: center;
  ${button({
    bg: "rgba(255, 255, 255, 0.148339)",
    color: "#807972",
    borderRadius: 28,
    animated: true,
  })};
`;

const ImageWrapper = styled.div`
  display: block;
  margin-bottom: ${scaleSpacing(4)};

  img {
    display: block;
    width: 100%;
  }
`;

const Section = ({
  description,
  logo,
  logoW,
  logoH,
  link,
  color,
  iscurrent,
}) => (
  <SectionWrapper color={color} iscurrent={iscurrent ? 1 : 0}>
    <ColLinkWrapper>
      <ColLink link={link}>
        <ImageWrapper>
          <Svg name={logo} size={logoW} height={logoH} fill={colors.white} />
        </ImageWrapper>
        <Description>{description}</Description>
      </ColLink>
      <ArrowLink link={link} linkTheme="area">
        <Svg name="arrow" size={6} height={12} fill={colors.white} />
      </ArrowLink>
    </ColLinkWrapper>
  </SectionWrapper>
);

Section.propTypes = {
  logo: PropTypes.string.isRequired,
  logoW: PropTypes.number.isRequired,
  logoH: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  iscurrent: PropTypes.bool,
};

const BurgerMenu = ({ withHeader, handleFocusInput, isSearch }) => {
  const {
    sections,
    menu,
    homeLink,
    showNewsletterIcon,
    showProfileIcon,
    showSearchIcon,
  } = headerAdapter() || {};

  const theme = useTheme();
  const [shown, setShown] = useState(false);
  const [menuOffset, setMenuOffset] = useState(false);
  const { showUserMenu } = useContext(InplayerContext);
  const { openModal } = useContext(UIContext);

  const MenuWrapperRef = useRef(null);

  const [{ opacityOverlay }, setOverlay] = useSpring(() => ({
    opacity: 0,
    from: { opacity: 0 },
    config: { duration: 400 },
  }));

  const [{ opacity, x }, setMenu] = useSpring(() => ({
    opacity: 0,
    x: 0,
    from: { opacity: 0, x: -100 },
    delay: 200,
    config: { duration: 100 },
  }));

  useEffect(() => {
    if (!withHeader) setTimeout(() => setShown(true), 2000);
  }, [withHeader]);

  const scrollY = useRef(0);

  useEffect(() => {
    document.body.classList.toggle("Menu__Body--open", shown);
    scrollY.current = window?.scrollY || 0;
    setMenuOffset(MenuWrapperRef?.current?.offsetTop - scrollY.current);
  }, [shown]);

  useEffect(() => {
    setOverlay({ opacityOverlay: shown ? 1 : 0 });
    setMenu({ opacity: shown ? 1 : 0, x: shown ? 0 : -100 });
  }, [setMenu, setOverlay, shown]);

  const handleClickUserMenu = useCallback(() => {
    setShown(false);
    showUserMenu();
  }, [showUserMenu]);

  const handleClickSearch = useCallback(() => {
    setShown(false);
    !isSearch
      ? openModal({
          modalId: "search",
        })
      : handleFocusInput();
  }, [handleFocusInput, isSearch, openModal]);

  return (
    <Wrapper ref={MenuWrapperRef}>
      <MenuOverlay
        onClick={() => setShown(false)}
        style={{
          opacity: opacityOverlay,
          display: opacity.to(o => (o === 0 ? "none" : "block")),
        }}
      />
      <MenuWrapper
        className="main-navigation"
        bgcolor={theme?.menuBgColor}
        offset={menuOffset ? menuOffset : null}
        style={{
          opacity,
          display: opacity.to(o => (o === 0 ? "none" : "block")),
          transform: x.to(x => `translateX(${x}%)`),
        }}
      >
        <LogoWrapper onClick={() => setShown(false)} color={theme?.primary}>
          <LogoLink link={homeLink}>
            <LogoSvg
              name={theme.logo}
              top={theme.logoOffsetTop}
              fill={theme?.menuColor}
            />
          </LogoLink>
          <Close
            name="cross"
            size={18.58}
            height={18.58}
            fill={theme?.menuColor}
          />
        </LogoWrapper>
        <Separator />

        {menu && (
          <CategoryNavigationLinks
            onClickUser={handleClickUserMenu}
            onClickSearch={handleClickSearch}
            menu={menu}
            showNewsletterIcon={showNewsletterIcon}
            showProfileIcon={showProfileIcon}
            showSearchIcon={showSearchIcon}
            isInsideNav
          />
        )}

        <Sections>
          {sections.map((s, index) => {
            if (s) {
              return (
                <Section
                  key={index}
                  logo={s.logo}
                  logoW={s.logoW}
                  logoH={s.logoH}
                  link={s.link}
                  description={s.description}
                  color={s.color}
                  iscurrent={s?.id === theme?.label}
                />
              );
            }
          })}
        </Sections>
      </MenuWrapper>
      {withHeader && (
        <Header
          menu={menu}
          theme={theme}
          homeLink={homeLink}
          showNewsletterIcon={showNewsletterIcon}
          showProfileIcon={showProfileIcon}
          showSearchIcon={showSearchIcon}
          onClickBurger={() => setShown(true)}
          onClickUser={handleClickUserMenu}
          onClickSearch={handleClickSearch}
        />
      )}
    </Wrapper>
  );
};

BurgerMenu.propTypes = {
  withHeader: PropTypes.bool,
  isSearch: PropTypes.bool,
  homeLink: PropTypes.object,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.object.isRequired,
      description: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      logoW: PropTypes.number.isRequired,
      logoH: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      id: PropTypes.string,
    })
  ),
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      link_path: PropTypes.object.isRequired,
      link_label: PropTypes.string.isRequired,
    })
  ),
  showNewsletterIcon: PropTypes.bool,
  showProfileIcon: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
  showSearch: PropTypes.func,
  handleFocusInput: PropTypes.func,
};

BurgerMenu.defaultProps = {
  withHeader: true,
  showSearch: () => {},
  handleFocusInput: () => {},
};

export default React.memo(BurgerMenu);
